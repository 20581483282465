<script context="module" lang="ts">
	export const borderColors = {
		primary: '#4beca4', // primary-500
		surface: '#a8b3cf', // surface-300
		tertiary: '#4dc3ff' // tertiary-500
	};
</script>

<script lang="ts">
	import { browser } from '$app/environment';
	import { onMount } from 'svelte';
	import { Motion, useMotionTemplate, useMotionValue, useTransform } from 'svelte-motion';

	export let rx: string = '30%';
	export let ry: string = '30%';
	export let duration = 8000;
	export let interval = 25;
	export let initialProgress = 0;

	// State
	let containerEl: HTMLDivElement; // used to track intersection
	let pathEl: SVGGeometryElement; // used to track path length

	// eslint-disable-next-line no-undef
	// let time: number 0= 0;
	let time: number = initialProgress * duration; // Adjusted time to start at initialProgress

	// Position stores
	// const progress = useMotionValue<number>(0);
	const progress = useMotionValue<number>(initialProgress);

	const x = useTransform(progress, (val) => pathEl?.getPointAtLength(val).x);
	const y = useTransform(progress, (val) => pathEl?.getPointAtLength(val).y);
	const transform = useMotionTemplate`translateX(${x}px) translateY(${y}px) translateX(-50%) translateY(-50%)`;

	function incrementRect() {
		time += interval;

		// This can error out if pathEl is not visible.
		// See https://stackoverflow.com/questions/64189686/failed-to-execute-gettotallength.
		try {
			const length = pathEl?.getTotalLength() ?? null;
			if (!length) return;
			const pxPerMillisecond = length / duration;
			progress.set((time * pxPerMillisecond) % length);
		} catch (e) {
			// No-op.
		}
	}

	onMount(() => {
		setInterval(incrementRect, interval);
	});
</script>

{#if browser}
	<svg
		height="100%"
		preserveAspectRatio="none"
		width="100%"
		xmlns="http://www.w3.org/2000/svg"
		class="absolute"
	>
		<rect bind:this={pathEl} fill="none" height="100%" {rx} {ry} width="100%" />
	</svg>

	<Motion let:motion>
		<div
			bind:this={containerEl}
			class="absolute left-0 top-0 inline-block"
			style:transform={$transform}
			use:motion
		>
			<slot />
		</div>
	</Motion>
{/if}
