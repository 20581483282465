<script lang="ts">
	import MovingBorder, { borderColors } from './MovingBorder.svelte';

	// Props...
	export let duration = 12500; // 7.5 seconds per rotation
	export let borderRadius = '1.75rem';
	export let borderColor: keyof typeof borderColors = 'surface';
	export let background: null | string = null;

	export let testid = '';
	export let classes: string = '';

	$: background = background ?? 'bg-component';
	$: borderHex = borderColors[borderColor];
</script>

<div
	data-testid={testid}
	class="relative w-full overflow-hidden bg-transparent p-[1px]"
	style:border-radius={borderRadius}
>
	<!-- 
		moving border el
	 -->
	<div class="absolute inset-0" style:border-radius={`calc(${borderRadius} * 0.96)`}>
		<MovingBorder {duration} rx="30%" ry="30%">
			<div
				class="h-20 w-20 opacity-[0.8] tablet:h-40 tablet:w-40"
				style="background: radial-gradient({borderHex} 40%, transparent 60%);"
			/>
		</MovingBorder>
	</div>

	<!-- 
		card el
	 -->
	<div
		class="
		relative
		mx-auto
		h-full
		w-full
		border
		border-base
		{background} 
		antialiased backdrop-blur-xl
		{classes}
		"
		style:border-radius={`calc(${borderRadius} * 0.96)`}
	>
		<slot />
	</div>
</div>
